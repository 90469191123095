<template>
  <div>
    <div
      class="inline-flex justify-between w-full pb-1 mt-6 font-medium tracking-wider uppercase border-b border-white/10"
    >
      <div
        @click.prevent="toggelGroup"
        class="inline-flex px-2 py-1 my-auto capitalize border border-transparent rounded cursor-pointer select-none text-neutral-100 group hover:bg-white/5 hover:border-white/10 "
      >
        {{ group.name }}
        <span
          class="ml-4 font-normal tracking-normal text-neutral-400"
          >{{ group.drives.length }}
          <template v-if="group.drives.length > 1">drives</template
          ><template v-else>drive</template></span
        >
      </div>
      <button
        @click.prevent="toggelGroup"
        class="inline-flex px-2 py-1 my-auto border border-transparent rounded hover:bg-white/5 group"
      >
        <fa-icon
          v-if="open"
          icon="chevron-down"
          class="my-auto text-neutral-600 group-hover:text-neutral-400"
        />
        <fa-icon
          v-else
          icon="chevron-right"
          class="my-auto text-neutral-600 group-hover:text-neutral-400"
        />
      </button>
    </div>
    <ul
      v-show="open"
      role="list"
      class="grid grid-cols-1 gap-6 py-6 border-b sm:grid-cols-2 border-white/10"
    >
      <DriveCard
        v-for="drive in group.drives"
        v-bind:key="drive.id"
        v-bind:drive="drive"
      />
    </ul>
  </div>
</template>
<script>
import DriveCard from '@/components/v4/dashboard/DriveCard.vue'

export default {
  name: 'DriveGroup',
  components: {
    DriveCard
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: true
    }
  },
  methods: {
    toggelGroup() {
      this.open = !this.open
    }
  }
}
</script>
