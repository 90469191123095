<template>
  <Layout>
    <div
      class="flex flex-1 px-4 py-12 bg-gray-800 dark:shadow-inner"
    >
      <div class="w-full max-w-2xl mx-auto">
        <div>
          <div class="flex items-baseline justify-between flex-1">
            <h1 class="text-2xl font-bold text-neutral-100">
              Drives
            </h1>
            <!-- <button
              @click.prevent="showProjectCreate"
              type="button"
              class="inline-flex items-center ml-4 px-3 py-1.5 text-sm font-medium leading-4 text-indigo-600 border border-indigo-600 bg-white rounded-md shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:border dark:border-transparent dark:text-neutral-100 dark:ring-offset-0 dark:border-indigo-900 dark:bg-indigo-700 dark:hover:bg-indigo-600 dark:focus:ring-1 dark:focus:border-indigo-600 dark:focus:ring-indigo-400"
            >
              <fa-icon icon="plus" class="mr-2 -ml-1" />New
            </button> -->
          </div>
          <p class="mt-1 text-sm text-neutral-400">
            Drives are collections of assets you and your teammates can store and collaborate on.
          </p>
          <DriveGroup v-for="org in orderedOrgs" :key="org.id" :group="org" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import DriveGroup from '@/components/v4/dashboard/DriveGroup.vue'

const Layout = () => import('@layouts/main')

export default {
  name: 'DriveSelect',
  components: {
    Layout,
    DriveGroup
  },
  data() {
    return {
      orgs: []
    }
  },

  computed: {
    orderedOrgs() {
      function compare(a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      return this.filteredOrgs.sort(compare)
    },

    items() {
      return this.$store.getters['projectsList/projects']
    },

    filteredOrgs() {
      this.orgs = []
      this.items.forEach((item) => {
        const matchedOrg = this.orgs.find((x) => x.id === item.org_id)
        if (matchedOrg) {
          matchedOrg.drives.push(item)
        } else {
          this.orgs.push({
            id: item.org_id,
            name: item.org_name,
            drives: [item]
          })
        }
      })
      return this.orgs
    }
  }
}
</script>
