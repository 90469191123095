<template>
  <router-link
    :to="toDriveView(drive.id)"
    class="relative flex items-center p-2 -m-2 space-x-4 border border-transparent shadow-inner rounded-xl ring-0 ring-offset-0 hover:bg-white/5 hover:border-white/10 hover:focus-within:border-transparent focus-within:ring-2 focus-within:ring-indigo-600"
  >
    <img
      v-if="hasCover"
      v-img-fallback="'large'"
      class="object-cover pointer-events-none"
      :src="drive.cover_url"
      :alt="drive.title"
    />
    <div
      v-else
      class="flex items-center justify-center flex-shrink-0 w-16 h-16 bg-blue-700 rounded-lg shadow-inner hover:bg-blue-600"
    >
      <fa-icon
        icon="hard-drive"
        class=" fa-fw text-neutral-100"
        size="lg"
      />
    </div>
    <button type="button" class="absolute inset-0 focus:outline-none">
      <span class="sr-only">View details for {{ drive.title }}</span>
    </button>
    <div>
      <h3 class="text-sm font-medium text-neutral-100">
        {{ drive.title }}
      </h3>
      <p class="mt-1 text-sm text-neutral-400">
        {{ drive.asset_count }} item(s) updated
        {{ drive.updated_at | relative }} ago
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    drive: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasCover() {
      // return this.drive.cover_url !== ''
      return false
    }
  },
  methods: {
    toDriveView(id) {
      return {
        name: 'drive-overview',
        params: {
          uuid: id
        }
      }
    }
  }
}
</script>
