<template>
  <Layout>
    <div
      class="w-full h-full px-4 py-6 overflow-hidden overflow-y-scroll bg-gray-800 lg:px-20 md:px-6"
    >
      <!-- <p class="text-sm font-normal leading-3 text-gray-600">Home / Previz / Recent</p>
                  <hr class="w-full my-6 bg-gray-200" />

                  <div class="flex items-center justify-between">
                      <div class="flex items-center justify-center space-x-3">
                          <svg class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.75 7.5H20.25" stroke="#1F2937" stroke-miterlimit="10" stroke-linecap="round" />
                              <path d="M3.75 12H20.25" stroke="#1F2937" stroke-miterlimit="10" stroke-linecap="round" />
                              <path d="M3.75 16.5H20.25" stroke="#1F2937" stroke-miterlimit="10" stroke-linecap="round" />
                          </svg>
                          <p class="text-base font-normal leading-4 text-gray-800">Filter</p>
                      </div>
                      <p class="text-base font-normal leading-4 text-gray-600 duration-100 cursor-pointer hover:underline">Showing {{items.length}} files</p>
                  </div> -->

      <!-- <p>Drive:: {{ drive.title }} // {{ drive.slug }} {{ drive.org_name }}</p> -->
      <div class="flex items-center justify-between px-4 md:px-6">
        <!-- <h3 class="text-sm capitalize ">{{ currentProject.title }}</h3> -->
        <div class="inline-flex items-center space-x-4">
          <div class="inline-flex items-center">
            <div
              class="relative flex items-center flex-shrink-0 px-4 py-4 mr-2 bg-blue-500 rounded"
            >
              <fa-icon icon="hard-drive" class="text-lg text-neutral-100" />
            </div>
            <h1 class="text-3xl font-semibold capitalize text-neutral-100">
              {{ drive.title }}
            </h1>
          </div>
          <router-link
            class="inline-flex p-1.5 h-fit mt-1 rounded-md text-neutral-100 hover:bg-white/5 border border-transparent hover:border-white/20"
            :to="{ name: 'drive-select' }"
            v-tooltip="'Change Drive'"
          >
            <fa-icon icon="arrow-right-arrow-left" />
          </router-link>
        </div>
        <div v-if="items.length">
          <button
            class="inline-flex items-center justify-center px-3 py-2 text-sm font-medium leading-4 text-white bg-indigo-600 border border-transparent rounded-md shadow-sm select-none dark:text-neutral-100 dark:ring-offset-0 focus:ring-indigo-500 dark:bg-indigo-700 dark:hover:bg-indigo-600 dark:focus:ring-1 dark:focus:border-indigo-600 dark:focus:ring-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
            :class="{
              'opacity-50 cursor-not-allowed': !hasPrevizAccess
            }"
            v-tooltip="createPrevizTooltipConfig"
            @click="showModalCreatePreviz"
          >
            <fa-icon icon="plus" class="mr-2 -ml-1" />
            New Previz
          </button>
        </div>
      </div>

      <!-- <div class="grid grid-cols-1 mt-10 lg:grid-cols-4 sm:grid-cols-2 lg:gap-y-12 lg:gap-x-8 sm:gap-y-10 sm:gap-x-6 gap-y-6 lg:mt-12">
            <div class="relative" v-for="item in items" :key="item.id">

              <router-link :key="item.id" :to="toEditor(item.id)">
                <img class="w-full aspect-square" src="https://images.unsplash.com/photo-1576514129883-2f1d47a65da6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80" :alt="item.imageAlt" />

                <div class="flex justify-between mt-4 md:mt-6">
                  <div>
                    <p class="text-xl font-normal leading-5 text-gray-800"> {{ item.name }}</p>
                    <p class="mt-2 text-xs text-gray-600">{{ item.updated_at }}</p>
                  </div>
                  <div class="text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>
                  </div>
                </div>

              </router-link>
            </div>
          </div> -->
      <template v-if="isLoadingEnabled">
        <div class="flex items-center justify-center mt-12 text-xl text-white">
          <fa-icon icon="spinner" spin class="mr-2" /> Loading
        </div>
      </template>
      <template v-else>
        <PrevizList v-if="items.length > 0" :drive="drive" />
        <div v-else class="flex justify-center">
          <EmptyPrevizList
            @createPreviz="showModalCreatePreviz"
            class="lg:w-5/6"
          />
        </div>
      </template>
    </div>
  </Layout>
</template>

<script>
import PrevizList from '@/components/v4/dashboard/PrevizList.vue'
import EmptyPrevizList from '@/components/v4/dashboard/EmptyPrevizList.vue'

import projectFeatureGates from '@mixins/project-feature-gates'
import previzAccessTooltips from '@mixins/previz-access-tooltips'

const Layout = () => import('@layouts/main')
const PrevizCreate = () => import('@modals/PrevizCreate')

export default {
  name: 'DriveOverview',
  components: {
    Layout,
    PrevizList,
    EmptyPrevizList
  },
  mixins: [projectFeatureGates, previzAccessTooltips],

  computed: {
    drive() {
      return this.$store.getters['project/project']
    },

    items() {
      return this.$store.getters['assets/getRecentAssetsByType'](
        this.drive.id,
        'previz'
      )
    },

    isLoadingEnabled() {
      return (
        this.$store.getters['projectsList/getIsProjectRequestPending'] ||
        this.$store.getters['assets/getIsPrevizInDriveRequestPending']
      )
    }
  },

  methods: {
    showModalCreatePreviz() {
      if (!this.hasFeatureAccess('previz.editor')) return
      this.$bus.$emit('overlay:modal', {
        component: PrevizCreate,
        props: {
          project: this.drive,
          parent: this.drive.root_folder
        }
      })
    }
  }
}
</script>
