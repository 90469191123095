<template>
  <div class="px-6 py-8 my-12 overflow-hidden bg-black h-1/2 rounded-xl">
    <div class="grid-cols-5 gap-4 xl:grid">
      <div
        class="flex flex-col items-end justify-between col-span-2 xl:items-start"
      >
        <div>
          <h3 class="mb-2 text-2xl text-white xl:text-3xl">
            Create Your First Previz
          </h3>
          <p class="text-white">
            Previz enables creative teams to publish and share 3D experiences
            and streamline their content review process. Get started by creating
            your first previz below.
          </p>
        </div>

        <div
          class="inline-flex items-end justify-center px-4 py-2 mt-4 text-base font-medium tracking-wide text-white uppercase transition-colors bg-black border border-teal-500 cursor-pointer focus:scale-105 hover:bg-teal-500 w-36"
          :class="{
            'opacity-50 cursor-not-allowed': !hasPrevizAccess
          }"
          v-tooltip="createPrevizTooltipConfig"
          @click="$emit('createPreviz')"
        >
          New Previz
        </div>
      </div>

      <div
        class="col-span-3 mt-4 border shadow-xl xl:-mr-16 xl:-mb-24 border-slate-800 rounded-xl max-h-[30rem]"
      >
        <img
          src="/img/onboarding/step-2.png"
          alt="Previz Get Started"
          class="rounded-xl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import previzAccessTooltips from '@mixins/previz-access-tooltips'

export default {
  name: 'EmptyPrevizList',

  mixins: [previzAccessTooltips]
}
</script>
