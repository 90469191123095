<template>
  <router-link
    class="p-4 md:p-6 flex-col flex aspect-[16/9] w-full border border-transparent rounded-lg"
    :class="{
      'hover:bg-white/5 hover:border-white/10':
        hasPrevizAccess && previz.editable && !previz.is_locked,
      'cursor-not-allowed':
        !hasPrevizAccess || !previz.editable || previz.is_locked
    }"
    :to="previz.editable && !previz.is_locked ? toEditor(previz.id) : '#'"
    v-tooltip="openPrevizTooltipConfig(previz)"
  >
    <!-- Hide image if thumbnail is null, needs empty string as null is not error -->
    <img
      class="items-center justify-center flex-shrink-0 rounded-md visible w-full aspect-[16/9]"
      :class="!thumbnailVisible ? 'hidden' : 'flex'"
      :src="Object.is(previz.thumbnail, null) || ''"
      :alt="'Thumbnail of ' + previz.name"
      @error="thumbnailVisible = false"
    />
    <div
      :class="!thumbnailVisible ? 'flex' : 'hidden'"
      class="relative items-center justify-center flex-shrink-0 w-full h-full bg-purple-600 rounded-md shadow-inner"
    >
      <fa-icon icon="bars-staggered" class="text-5xl text-neutral-100" />
      <LockBadge
        class="absolute text-xl top-4 right-2 text-neutral-100"
        :locked="previz.is_locked"
        show-only-if-locked
        hide-tooltip
      />
    </div>
    <div class="flex justify-between mt-4">
      <div class="flex flex-col">
        <p class="text-xs text-neutral-400">
          Uploaded by {{ previz.uploaded_by_name }}
        </p>
        <p
          v-bind:class="
            previz.editable ? 'text-neutral-100' : 'text-neutral-500'
          "
          class="mt-2 text-xl font-medium leading-5 capitalize"
        >
          {{ previz.name }}
        </p>
        <p class="mt-1 text-xs font-bold text-neutral-400">
          {{ previz.updated_at | relative }} ago
        </p>
      </div>
      <div class="flex items-center">
        <AssetItemViewOptions
          v-if="hasPrevizAccess"
          class="pl-2"
          v-bind:asset="previz"
          v-bind:can-delete="true"
          @rename-asset="editPreviz(previz)"
          @delete-asset="deletePreviz(previz)"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import projectFeatureGates from '@mixins/project-feature-gates'
import previzAccessTooltips from '@mixins/previz-access-tooltips'

const AssetRenameModal = () => import('@modals/AssetRename')
const AssetDeleteModal = () => import('@modals/AssetDelete')
const AssetItemViewOptions = () =>
  import('@components/assets/asset-item-view-options')
const LockBadge = () => import('@components/badges/ContentLockStatusBadge')

export default {
  name: 'PrevizListCard',
  components: { AssetItemViewOptions, LockBadge },

  props: {
    previz: {
      type: Object,
      required: true
    }
  },
  mixins: [projectFeatureGates, previzAccessTooltips],
  data() {
    return {
      thumbnailVisible: true
    }
  },
  computed: {
    drive() {
      return this.$store.getters['project/project']
    },
    toPrevizSelect() {
      if (this.currentProject) return `/d/${this.currentProject.id}`
      return null
    },
    hasPrevizAccess() {
      return this.hasFeatureAccess('previz.editor')
    }
  },
  methods: {
    toEditor(id) {
      if (!this.hasPrevizAccess) {
        return {
          name: 'drive-overview',
          params: {
            uuid: this.drive.id
          }
        }
      }
      return {
        name: 'editor',
        params: {
          uuid: id
        },
        query: {
          drive: this.drive.id
        }
      }
    },
    editPreviz(asset) {
      this.$bus.$emit('overlay:modal', {
        component: AssetRenameModal,
        props: {
          asset: asset
        }
      })
    },
    deletePreviz(asset) {
      this.$bus.$emit('overlay:modal', {
        component: AssetDeleteModal,
        props: {
          asset: asset
        }
      })
    }
  }
}
</script>
