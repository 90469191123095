<template>
  <Layout>
    <div class="flex flex-col h-full md:flex-row">
      <!-- Sidebar -->
      <div
        class="flex flex-col items-center justify-between order-2 w-full h-auto bg-white md:h-full md:w-1/3 md:order-first"
      >
        <div></div>
        <div class="p-8 md:px-16">
          <ul
            v-for="(step, index) in steps"
            :key="index"
            class="flex list-none align-center"
          >
            <TransitionGroup
              enter-class="-ml-16 opacity-0"
              enter-active-class="transition-all duration-300 ease-in-out md:duration-700"
              enter-to-class="ml-0 opacity-1"
            >
              <li v-if="index === currentStep" :key="step.title">
                <div class="flex mb-8 md:mb-16">
                  <div class="flex flex-col items-center align-middle">
                    <img
                      src="/img/icons/disguise/previz-app-icon.png"
                      alt="App Name"
                      class="w-14 md:w-28"
                    />
                    <h4 class="font-medium font-display">Previz</h4>
                  </div>
                </div>
                <h2
                  class="mt-1 text-3xl text-gray-900 sm:text-4xl sm:tracking-tighter lg:text-5xl xl:w-3/4"
                >
                  {{ step.title }}
                </h2>
                <p class="max-w-xl mt-8 text-base text-gray-900 sm:text-lg">
                  {{ step.subTitle }}
                </p>
              </li>
            </TransitionGroup>
          </ul>
        </div>

        <div class="flex items-center justify-between w-full p-4 sm:p-8">
          <div
            class="inline-flex items-center p-2 bg-transparent border border-transparent rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            :class="{ invisible: currentStep === 0 }"
            @click="prevStep"
          >
            <fa-icon
              icon="chevron-right"
              class="text-gray-400 rotate-180 fa-fw"
            />
          </div>
          <div
            class="max-w-xl mx-auto text-base text-gray-500 select-none sm:text-lg"
          >
            <div
              class="flex items-center space-x-1 text-sm font-medium font-display"
            >
              <span
                v-for="(paginationStep, paginationIndex) in steps"
                class="relative flex items-center justify-center w-10 h-10 rounded-full cursor-pointer"
                :class="{
                  'bg-[#344054] text-white': paginationIndex === currentStep,
                  'text-[#344054] hover:bg-gray-200':
                    paginationIndex !== currentStep
                }"
                :key="paginationIndex"
                @click="handleJump(paginationIndex)"
                >{{ paginationIndex + 1 }}</span
              >
            </div>
          </div>
          <div
            class="inline-flex items-center p-2 bg-transparent border border-transparent rounded-full cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            :class="{ invisible: !(currentStep < steps.length - 1) }"
            @click="handleNext"
          >
            <fa-icon icon="chevron-right" class="text-gray-600 fa-fw" />
          </div>
        </div>
      </div>
      <div
        class="relative flex flex-col items-center justify-between w-full overflow-hidden h-2/3 md:h-full md:w-2/3 bg-gradient-to-r md:items-end"
        :class="{ [steps[currentStep].gradient]: true }"
      >
        <div
          class="z-50 m-8 text-right text-gray-200 cursor-pointer hover:text-white sm:block"
          @click="goToRedirect"
        >
          Skip all
        </div>
        <div class="z-50 hidden m-8 text-right sm:block">
          <div
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white uppercase bg-transparent border-2 border-[#6BFFDC] cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-display"
            @click="handleNext"
          >
            Next
          </div>
        </div>

        <div
          class="absolute px-4 translate-y-12 sm:p-6 sm:bottom-auto sm:translate-x-24 sm:top-1/2 sm:-translate-y-2/4 top-8"
        >
          <ul
            v-for="(step, index) in steps"
            :key="index"
            class="flex list-none align-center"
          >
            <TransitionGroup
              enter-class="-translate-x-[100%] opacity-0"
              enter-active-class="transition-all duration-300 ease-in-out md:duration-700"
              enter-to-class="translate-x-0 opacity-1"
            >
              <li v-if="index === currentStep" :key="step.imgSrc">
                <picture>
                  <source class="rounded-lg" :srcset="step.imgSrc" />
                  <img
                    class="rounded-lg"
                    :src="step.imgSrc"
                    :alt="step.altText"
                  />
                </picture>
              </li>
            </TransitionGroup>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
const Layout = () => import('@layouts/main')

export default {
  name: 'user-onboarding',

  mixins: [],

  components: {
    Layout
  },

  data() {
    return {
      steps: [
        {
          title: 'Plan, preview and share',
          subTitle:
            'Previz enables you to import, pre-visualise, edit and share 3D environments in one place.',
          imgSrc: '/img/onboarding/step-1.png',
          altText: 'disguise drive with content',
          gradient: 'from-gray-800 to-gray-600'
        },
        {
          title: 'Easily collaborate with your teams',
          subTitle:
            'Previz enables creative teams to publish and share 3D visualisations and streamline their content review process.',
          imgSrc: '/img/onboarding/step-2.png',
          altText: 'Reviewing content and making comments',
          gradient: 'from-gray-800 to-gray-600'
        },
        {
          title: 'A streamlined content review process',
          subTitle:
            'From Previz and Designer, you can export an interactive 3D environment with sequenced content from either timeline. Generate a link to share with your teams and clients.',
          imgSrc: '/img/onboarding/step-3.png',
          altText: 'Account management screen',
          gradient: 'from-gray-800 to-gray-600'
        },
        {
          title: 'Faster and more seamless iterations',
          subTitle:
            'Speed up your workflow with faster pre-production and review processes ahead of production time.',
          imgSrc: '/img/onboarding/step-4.png',
          altText: 'Account management screen',
          gradient: 'from-gray-800 to-gray-600'
        }
      ],
      currentStep: 0
    }
  },

  computed: {
    needsOnboarding() {
      return this.$store.getters['doesUserNeedOnboardingPreviz']
    },
    nextQuery() {
      return this.$route.query.next
    }
  },

  methods: {
    handleNext() {
      this.currentStep < this.steps.length - 1
        ? this.nextStep()
        : this.goToRedirect()
    },
    handlePrevious() {
      if (this.currentStep > 0) this.prevStep()
    },
    handleJump(step) {
      this.currentStep = step
    },
    async goToRedirect() {
      if (this.needsOnboarding) {
        await this.markOnboardingAsFinished()
      }

      if (this.nextQuery) {
        return this.$router.push({ path: this.nextQuery })
      } else {
        return this.$router.push({ name: 'dashboard' })
      }
    },
    nextStep() {
      this.currentStep++
    },
    prevStep() {
      this.currentStep--
    },
    markOnboardingAsFinished() {
      return this.$store.dispatch('setNeedsOnboardingPreviz', false)
    },
    keyboardListeners(e) {
      if (e.key === 'ArrowLeft') this.handlePrevious()
      if (e.key === 'ArrowRight') this.handleNext()
    }
  },

  mounted() {
    window.addEventListener('keydown', this.keyboardListeners)

    setTimeout(() => {
      this.markOnboardingAsFinished()
    }, 500)
  },

  destroyed() {
    window.removeEventListener('keydown', this.keyboardListeners)
  }
}
</script>
