<template>
  <Layout>
    <template v-slot:rightcomp v-if="asset">
      <div class="inline-flex space-x-2">
        <SaveStateControls
          v-bind:asset="asset"
          v-bind:timeline-save-timeout="timelineSaveTimeout"
          v-bind:timeline-save-in-progress="timelineSaveInProgress"
          v-bind:timeline-save-last-success="timelineSaveLastSuccess"
          v-on:save-immediate-request="exportSequence"
        />

        <button
          v-on:click.prevent="onShare"
          class="inline-flex items-center justify-center px-3 py-2 text-sm font-medium leading-4 text-white bg-indigo-600 border border-transparent rounded-md shadow-sm select-none dark:text-neutral-100 dark:ring-offset-0 focus:ring-indigo-500 dark:bg-indigo-700 dark:hover:bg-indigo-600 dark:focus:ring-1 dark:focus:border-indigo-600 dark:focus:ring-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
        Share <fa-icon class="ml-2 fa-fw" icon="link" />
        </button>
      </div>
    </template>

    <!--
   <SceneNavBar
      v-if="asset"
      :mode="'editor'"
      :asset="asset"
      :last-changed="lastSceneChanged"
      @save-request="onSaveRequest"
      @export-request="onExportRequest"
      @export-settings-request="onExportSettingsRequest"
      @export-resources-request="onExportResourcesRequest"

    />-->

    <div
      class="flex flex-col flex-1"
      @dragenter.prevent.stop="onDragEnter"
      @dragover.prevent.stop="onDragOver"
      @dragleave.prevent.stop="onDragLeave"
      @drop.prevent.stop="onDrop"
    >
      <EditorGrid
        v-if="asset && sequence && resources"
        :viewer="viewer"
        :sequence="sequence"
        :resources="resources"
        :asset="asset"
        :play-mode="playMode"
        :export-scene-requested="exportSceneRequested"
        :export-settings-requested="exportSettingsRequested"
        @settings-changed="onSettingsChanged"
        @timeline-changed="onTimelineChanged"
      />
    </div>
  </Layout>
</template>
<script>
import saveExportBehaviours from '@components/editor/saveExportBehaviours.js'
import AssetShare from '@modals/AssetShare'
const Layout = () => import('@layouts/main')
const EditorGrid = () => import('@components/splitgrid/EditorGrid')
const DraggingToast = () => import('@components/editor/DraggingToast')
const FirstRunModal = () => import('@modals/FirstRunModal')
const SaveStateControls = () => import('@components/editor/SaveStateControls')

export default {
  name: 'EditorIndex',
  components: {
    Layout,
    EditorGrid,
    DraggingToast,
    SaveStateControls
  },

  mixins: [saveExportBehaviours],

  data() {
    return {
      viewer: null,
      isDev: false,
      playMode: false,
      exportSceneRequested: null,
      exportSettingsRequested: null,

      lastSettingsChanged: null,
      lastChanged: null,

      isDragging: false,
      hasFile: false // to show overlay
    }
  },

  computed: {
    asset() {
      return this.$store.getters['assets/getAssetById'](this.assetId)
    },

    assetId() {
      return this.$route.params.uuid
    },

    isBaseSceneActive() {
      return !this.sequencerQueryId
    },

    timeline() {
      if (this.hasTimeline) return this.viewer.core.sequence.timeline
      return null
    },

    hasTimeline() {
      return (
        this.viewer &&
        this.viewer.core &&
        this.viewer.core.sequence &&
        this.viewer.core.sequence.timeline
      )
    },

    sequencerQueryId() {
      return this.$route.query.sequence
    },

    hasChanges() {
      if (this.timeline) return this.timeline.hasChanges
      return false
    },

    hasSequence() {
      return this.viewer && this.viewer.core.sequence
    },

    activeSequencerId() {
      if (this.hasSequence) return this.viewer.core.sequence.id
      return null
    },

    sequencerAsset() {
      return this.$store.getters['assets/getAssetById'](this.assetId)
    },

    validAsset() {
      return this.sequencerAsset !== null
    },

    url() {
      if (this.validAsset) return this.sequencerAsset.url
      return null
    },

    data() {
      if (this.validAsset)
        return this.$store.getters['sequencer/getDataById'](
          this.sequencerAsset.id
        )
      return null
    },

    sequence() {
      if (this.data !== null && this.data.sequence) return this.data.sequence
      return null
    },

    resources() {
      if (this.data !== null && this.data.resources) return this.data.resources
      return null
    },

    hasData() {
      return this.sequence !== null && this.resources !== null
    }
  },
  watch: {
    // hasChanges: {
    //   handler (value) {
    //     if (value === false && this.nextRequestedActiveId !== null) {
    //       let id = this.nextRequestedActiveId
    //       this.nextRequestedActiveId = null
    //       this.$router.push({
    //         query: {
    //           sequence: id
    //         }
    //       })
    //     }
    //   }
    // },

    hasData: {
      handler: function (value) {
        if (value === true) {
          this.initViewer()
        }
      },
      immediate: true
    },

    validAsset: {
      handler: function (value) {
        if (value === true) {
          this.loadSequence()
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.attachViewerReadyListener()
  },

  methods: {
    loadSequence() {
      if (this.validAsset) {
        this.$store.dispatch('sequencer/loadFromUrl', {
          id: this.assetId,
          url: this.url
        })
      }
    },

    initViewer() {
      this.$nextTick(() => {
        if (
          this.sequence.world === null ||
          this.sequence.world.objects === null ||
          this.sequence.world.objects === undefined ||
          this.sequence.world.objects.length < 1
        ) {
          this.viewFirstRun()
        }
      })
    },

    selectLayout(layout) {
      this.selectedLayout = layout
    },

    dettachUnloadListener() {
      window.removeEventListener('beforeunload', this.checkUnloadGuard)
    },

    viewFirstRun() {
      this.$bus.$emit('overlay:modal', {
        component: FirstRunModal,
        size: 'md',
        dismissable: false
      })
    },

    attachUnloadListener() {
      window.addEventListener('beforeunload', this.checkUnloadGuard)
    },

    attachViewerReadyListener() {
      window.addEventListener(
        'previz-viewer-attached',
        function (event) {
          this.handleViewerAttached(event.detail)
        }.bind(this),
        false
      )
    },

    handleViewerAttached(viewer) {
      if (viewer !== undefined) {
        this.viewer = viewer
        window['previzeditor'] = viewer
      }
    },

    viewDemo(url) {
      this.hasFile = true
      this.$bus.$emit('scene:model:import', url)
    },

    onDrop(event) {
      this.isDragging = false
      this.handleFileDrop(event.dataTransfer.items)
    },

    onDragEnter(event) {
      this.isDragging = true
    },

    onDragLeave(event) {
      this.isDragging = false
    },

    onDragOver(event) {
      this.isDragging = true
    },

    handleFileDrop(items) {
      const handle = (file, path) => {
        if (file.name.startsWith('.')) return
        this.hasFile = true
        this.$bus.$emit('scene:model:import', file)
      }

      const traverse = (item, path) => {
        path = path || ''

        if (item.isFile) {
          item.file((file) => {
            handle(file, path)
          })
        }

        if (item.isDirectory) {
          let dirReader = item.createReader()
          dirReader.readEntries(function (entries) {
            Array.from(entries).forEach((child) => {
              traverse(child, path + item.name + '/')
            })
          })
        }
      }

      Array.from(items).forEach((item) => {
        item = item.webkitGetAsEntry()
        if (item) {
          traverse(item)
        }
      })
    },
    onShare() {
      this.$bus.$emit('overlay:modal', {
        component: AssetShare,
        props: { asset: this.asset }
      })
    }
  }
}
</script>
