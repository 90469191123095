import PrevizDirectUpload from '@/libs/direct-upload.js'
const DirectUpload = new PrevizDirectUpload()

export default {
  data() {
    return {
      autosaveTimelineEnabled: true,
      autosaveSettingsEnabled: true,
      autosaveEnabled: false,

      exportRequested: null,
      exportSceneRequested: null,
      exportResourcesRequested: null,
      exportSequenceRequested: null,

      saveSceneRequested: null,
      saveSettingsRequested: null,

      timelineSaveTimoutDelay: 5000, // 5 seconds
      timelineSaveTimeout: null,
      timelineSaveLastSuccess: null,
      timelineSaveInProgress: false,

      lastSettingsChanged: null,
      settingsSaveTimeout: null,
      settingsSaveTimeoutDelay: 2500, // 2.5 seconds

      sceneSaveTimeout: null,
      sceneSaveTimeoutDelay: 1500, // 1.5 seconds

      sceneQuickSaveTimeout: null,
      sceneQuickSaveTimeoutDelay: 30000 // 30 seconds
    }
  },

  mounted() {
    this.attachGlobalListeners()
    this.attachUnloadListener()
  },

  beforeDestroy() {
    this.dettachUnloadListener()
    this.dettachGlobalListeners()
  },

  beforeRouteLeave(to, from, next) {
    if (this.lastSceneChanged !== null) {
      if (
        !window.confirm('Are you sure? Leaving will lose any unsaved changes?')
      ) {
        next(false)
      } else {
        next()
      }
    } else {
      next()
    }
  },

  computed: {
    lastSceneChanged() {
      if (this.viewer && this.viewer.core && this.viewer.core.gui)
        return this.viewer.core.gui.lastChange
      return null
    }
  },

  watch: {
    lastSceneChanged(val) {
      if (val !== null) this.onSceneQuickChanged()
    }
  },

  methods: {
    dettachGlobalListeners() {
      this.$bus.$off('scene:model:resource-association-updated')
      this.$bus.$off('scene:model:request-full-save')
    },

    attachGlobalListeners() {
      this.$bus.$on(
        'scene:model:resource-association-updated',
        this.onResourceAssociationUpdated
      )
      this.$bus.$on(
        'scene:model:request-full-save',
        this.onBusRequestedFullSave
      )
    },

    onBusRequestedFullSave() {
      this.onSaveRequest('save')
    },

    onSettingsExported(data, handleAs) {
      if (handleAs === 'save') {
        this.$store.dispatch('assets/updateAssetSettings', {
          id: this.asset.id,
          settings: data
        })
      } else {
        console.log('EXPORT SETTINGS ARE - ', data)
      }
    },

    onResourcesExported(data) {
      // Push to a file download
      var FileSaver = require('file-saver')

      data.forEach((file) => {
        FileSaver.saveAs(file)
      })
    },

    onResourceAssociationUpdated(data) {
      this.$store
        .dispatch('assets/updateResourceAssociation', {
          asset_id: this.asset.id,
          key: data.key,
          resource_id: data.resource_id,
          type: data.type
        })
        .then(() => {
          alert.success('Texture Map Update Saved')
        })
        .catch((err) => {
          alert.error('Failed to save texture map update')
          console.warn('Failed updating resource association', err)
        })
    },

    handleSceneExport(data, handleAs) {
      let file = data.scene
      let extra = null
      const promiseArray = []

      if (handleAs === 'save' || handleAs === 'quicksave') {
        let resources = []

        if (handleAs === 'save') {
          if (data.resources !== undefined) {
            resources.push(...data.resources)
          }
        }

        // // Part of the above temp fix.
        // // Ideally we only want to save the textures that have actually changed.
        // // But if any texture has changed, resave them all, exluding binaries :(
        // if (handleAs === 'texturesave') {
        //   if (data.resources !== undefined) {
        //     data.resources.forEach(row => {
        //       if (!row.name.includes('.bin')) {
        //         resources.push(row)
        //       }
        //     })
        //   }
        // }

        if (handleAs === 'quicksave') {
          if (data.resources !== undefined) {
            data.resources.forEach((row) => {
              if (row.name.includes('.bin')) {
                resources.push(row)
              }
            })
          }
        }

        console.log(
          '(' +
            resources.length +
            ') resources marked for save. Running as ' +
            handleAs,
          resources
        )

        resources.forEach((resource) => {
          promiseArray.push(
            new Promise((resolve, reject) => {
              this.handleResourceUpload(resource)
                .then((response) => {
                  resolve(response)
                })
                .catch((err) => {
                  reject(err)
                })
            })
          )
        })

        promiseArray.push(
          new Promise((resolve, reject) => {
            this.$store
              .dispatch('assets/uploadVersion', {
                file: file,
                asset: this.asset,
                extra: extra
              })
              .then((response) => {
                // Dispatch a forced load event so the app has the latest updated version
                this.forceReloadAssetData()
                resolve(response)
              })
              .catch((err) => {
                reject(err)
              })
          })
        )

        return Promise.all(promiseArray)
      } else {
        // Push to a file download
        var FileSaver = require('file-saver')
        FileSaver.saveAs(file)
      }
    },

    handleResourceUpload(file) {
      return new Promise((resolve, reject) => {
        DirectUpload.store(file)
          .then((response) => {
            this.$store
              .dispatch('assets/updateResources', {
                uuid: response.uuid,
                key: response.key,
                name: file.name,
                content_type: file.type,
                asset_id: this.asset.id
              })
              .then((response) => {
                resolve(response)
              })
              .catch((err) => {
                reject(err)
              })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // onTimelineSaveRequest () {
    //   clearTimeout(this.timelineSaveTimeout) // just in case
    //   this.exportSequence()
    // },

    onSceneChanged() {
      if (!this.autosaveEnabled) return

      clearTimeout(this.sceneSaveTimeout)
      this.sceneSaveTimeout = setTimeout(() => {
        clearTimeout(this.sceneQuickSaveTimeout) // Just in case there was a pending quicksave trigger
        this.onSaveRequest()
      }, this.sceneSaveTimeoutDelay)
    },

    onSceneQuickChanged() {
      if (!this.autosaveEnabled) return

      clearTimeout(this.sceneQuickSaveTimeout)
      this.sceneQuickSaveTimeout = setTimeout(() => {
        this.onSaveRequest('quicksave')
      }, this.sceneQuickSaveTimeoutDelay)
    },

    onSettingsChanged() {
      if (!this.autosaveSettingsEnabled) return
      // Settings get an auto save behaviour
      // This has a timeout in place to prevent over-saving too many changes
      clearTimeout(this.settingsSaveTimeout)
      this.settingsSaveTimeout = setTimeout(() => {
        this.exportSettingsAndSave()
      }, this.settingsSaveTimeoutDelay)
    },

    exportSettingsAndSave() {
      this.viewer.core.exportSceneSettings((output) => {
        this.onSettingsExported(output, 'save')
      })
    },

    onTimelineSaveRequest() {
      clearTimeout(this.timelineSaveTimeout) // just in case
      this.exportSequence()
    },

    onTimelineChanged() {
      if (!this.autosaveTimelineEnabled) return
      // Timelines get an auto save behaviour
      // This has a timeout in place to prevent over-saving too many changes
      clearTimeout(this.timelineSaveTimeout)
      this.timelineSaveTimeout = setTimeout(() => {
        this.exportSequence()
      }, this.timelineSaveTimoutDelay)
    },

    exportSequence() {
      this.timelineSaveInProgress = true
      this.viewer.core.exportPreviz((output) => {
        this.$store
          .dispatch('sequencer/saveExport', {
            data: output
          })
          .then((res) => {
            if (res) {
              this.timelineSaveLastSuccess = Date.now()
            }
            this.timelineSaveTimeout = null
            this.timelineSaveInProgress = false
          })
      })
    },

    forceReloadAssetData() {
      this.$store.dispatch('assets/loadAsset', {
        id: this.asset.id,
        force: true
      })
    },

    onExportSettingsRequest() {
      this.viewer.core.exportSceneSettings((output) => {
        this.onSettingsExported(output, 'export')
      })
    },

    onExportResourcesRequest() {
      this.viewer.core.exportSceneResources((output) => {
        this.onResourcesExported(output)
      })
    },

    onExportRequest() {
      this.viewer.core.exportScene((output) => {
        this.handleSceneExport(output, 'export')
          .then((response) => {
            console.log('SCENE EXPORT COMPLETE', response)
          })
          .catch((err) => {
            console.log('Error on export', err)
          })
      })
    },

    onSaveRequest(type) {
      if (this.saveSceneRequested === true) return

      if (type === undefined) type = 'quicksave'

      if (this.viewer === null) return

      this.saveSceneRequested = true
      this.viewer.core.exportScene((output) => {
        this.handleSceneExport(output, type)
          .then((response) => {
            this.saveSceneRequested = false
            this.viewer.core.gui.clearChanges()
            this.$bus.$emit('scene:model:save-request-completed', {
              type: type
            })
            return true
          })
          .catch((err) => {
            console.log('Error on export?', err)
            this.$bus.$emit('scene:model:save-request-failed', {
              err: err,
              type: type
            })
            return false
          })
      })
    },

    checkUnloadGuard(event) {
      if (this.lastSceneChanged !== null) {
        if (
          !window.confirm(
            'Are you sure? Leaving will lose any unsaved changes?'
          )
        ) {
          event.preventDefault()
          event.returnValue = '' // Chrome requires this
        }
      }
    }
  }
}
