export default class OrgMemberRoles {
  static UNSET = new OrgMemberRoles('UNSET', 'unset')
  static OWNER = new OrgMemberRoles('OWNER', 'owner')
  static ADMIN = new OrgMemberRoles('ADMIN', 'admin')
  static MANAGEMENT = new OrgMemberRoles('MANAGEMENT', 'management')
  static MEMBER = new OrgMemberRoles('MEMBER', 'member')
  static REVIEWER = new OrgMemberRoles('REVIEWER', 'guest_reviewer')
  static COLLABORATOR = new OrgMemberRoles('COLLABORATOR', 'guest_collaborator')

  constructor(name, value) {
    this.name = name
    this.value = value
  }

  /**
   * @returns {OrgMemberRoles[]} - list of roles that are considered guests
   */
  static guestRoles() {
    return [OrgMemberRoles.REVIEWER, OrgMemberRoles.COLLABORATOR]
  }

  /**
   * @returns {string[]} - list of features that are restricted from guests
   */
  static guestRestrictedFeatures() {
    return ['previz.editor']
  }
}
