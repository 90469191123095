import projectFeatureGates from '@mixins/project-feature-gates'

const noAccessHTML = `
<div>
You do not have access to the Previz Editor on this organisation. 

<div class='mt-2 text-xs'>Please select a different drive via the Change Drive button that does have access.</div>

</div>`

const previzAccessTooltips = {
  mixins: [projectFeatureGates],

  methods: {
    /**
     * To be called from the template for a v-tooltip directive
     * @param {Object} file - the previz file object from the store
     * @returns {Object} - tooltip config for v-tooltip for open buttons
     */
    openPrevizTooltipConfig(file) {
      let tooltip = ''
      let tooltipClass = ''
      if (this.hasPrevizAccess && !file.is_locked) {
        tooltip = ''
      } else if (this.hasPrevizAccess && file.is_locked) {
        tooltip = 'This file is locked and cannot be edited.'
      } else if (!this.hasPrevizAccess) {
        tooltip = noAccessHTML
        tooltipClass = 'w-1/4'
      }

      return {
        class: tooltipClass,
        content: tooltip,
        html: true
      }
    }
  },

  computed: {
    hasPrevizAccess() {
      return this.hasFeatureAccess('previz.editor')
    },
    /**
     * To be used from the template for a v-tooltip directive
     * @returns {Object} - tooltip config for v-tooltip for create buttons
     */
    createPrevizTooltipConfig() {
      return {
        class: this.hasPrevizAccess ? '' : 'w-1/4',
        content: this.hasPrevizAccess
          ? 'Create a new Previz file'
          : noAccessHTML,
        html: true
      }
    }
  }
}

export default previzAccessTooltips
