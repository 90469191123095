<template>
  <div
    class="grid grid-cols-1 mt-4 lg:grid-cols-4 sm:grid-cols-2 lg:gap-y-12 lg:gap-x-8 sm:gap-y-10 sm:gap-x-6 gap-y-6 lg:mt-6"
  >
    <PrevizListCard
      v-for="previz in previzList"
      :key="previz.id"
      :previz="previz"
    />
  </div>
</template>

<script>
import PrevizListCard from '@/components/v4/dashboard/PrevizListCard.vue'

export default {
  name: 'PrevizList',
  props: {
    drive: {
      type: Object,
      required: true
    }
  },
  components: {
    PrevizListCard
  },
  computed: {
    previzList() {
      return this.$store.getters['assets/getRecentAssetsByType'](
          this.drive.id,
          'previz'
        )
    }
  }
}
</script>
