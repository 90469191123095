<template>
  <Layout>
    <div
      class="w-full h-auto px-4 py-10 mx-auto bg-white max-w-3x lg:max-w-screen-2xl"
    >
      <div class="max-w-lg mx-auto">
        <div class="flex items-baseline justify-between flex-1">
          <h1 class="text-2xl font-bold text-gray-900">Available Drives</h1>
          <Link
            class="inline-flex items-center ml-4 px-3 py-1.5 text-sm font-medium leading-4 text-indigo-600 border border-indigo-600 bg-white rounded-md shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <fa-icon icon="plus" class="mr-2 -ml-1" />New
          </Link>
        </div>
        <p class="mt-1 text-sm text-gray-500">
          Select a drive to view available Previz files
        </p>

        <template v-for="org in orderedOrgs">
          <p class="mt-6 text-sm text-gray-500">{{ org.name }}</p>
          <div role="list" class="mt-2 mb-4 space-y-1">
            <template v-for="drive in org.drives">
              <router-link
                :to="toDriveView(drive.id)"
                class="flex w-full group"
              >
                <DriveCard :drive="drive" />
              </router-link>
            </template>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<script>
import DriveCard from '@/components/v4/dashboard/DriveCard.vue'
const Layout = () => import('@layouts/main')

export default {
  name: 'Dashboard',
  components: {
    Layout,
    DriveCard
  },
  data() {
    return {
      orgs: []
    }
  },

  computed: {
    orderedOrgs() {
      function compare(a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      return this.filteredOrgs.sort(compare)
    },

    items() {
      return this.$store.getters['projectsList/projects']
    },

    filteredOrgs() {
      this.orgs = []
      this.items.forEach((item) => {
        const matchedOrg = this.orgs.find((x) => x.id === item.org_id)
        if (matchedOrg) {
          matchedOrg.drives.push(item)
        } else {
          this.orgs.push({
            id: item.org_id,
            name: item.org_name,
            drives: [item]
          })
        }
      })
      return this.orgs
    }
  },

  methods: {
    toDriveView(id) {
      return {
        name: 'drive-overview',
        params: {
          uuid: id
        }
      }
    }
  }
}
</script>
