import OrgMemberRoles from '@src/enums/orgMemberRole'

export default {
  computed: {
    currentProject() {
      return this.$store.getters['project/project']
    }
  },

  methods: {
    hasFeatureAccess(feature) {
      // If the user is a guest, and the feature is restricted from guests, deny access
      if (
        OrgMemberRoles.guestRoles()
          .map((role) => role.value)
          .includes(this.currentProject.org_role) &&
        OrgMemberRoles.guestRestrictedFeatures().includes(feature)
      ) {
        return false
      }

      return (
        this.currentProject &&
        this.currentProject.features.includes(feature) &&
        !this.isFeatureDisabled(feature)
      )
    },

    isFeatureDisabled(feature) {
      return (
        this.currentProject &&
        this.currentProject.disabled_features.includes(feature)
      )
    },

    isFeatureEnabled(feature) {
      return !this.isFeatureDisabled(feature)
    }
  }
}
